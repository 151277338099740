


































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { INotifications } from '@/types/types';
import charAt from '@/filters/charAt';

@Component({
  name: 'Notifications',
  filters: {
    charAt,
  },
})
export default class Notifications extends Vue {
  @Prop() notification!: INotifications;
  @Prop() id!: string;
  @Prop() isActive!: boolean;
  @Prop({ required: false }) avatar!: string;
  @Prop() title!: string;
  @Prop() subTitle!: string;

  @Emit('selectedItem')
  selectedItem() {
    return this.id;
  }
}
